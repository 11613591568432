import React from 'react';
import 'material-icons/iconfont/material-icons.css';

const IconoTexto = ({ icono, texto }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <i className="material-icons" style={{ fontSize: '24px' }}>
        {icono}
      </i>
      <span style={{ marginTop: '5px' }}>{texto}</span>
    </div>
  );
};

export default IconoTexto;