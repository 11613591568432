import React, { useState, useEffect } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import axios from 'axios';

function Home() {
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [t, i18n] = useTranslation("global");
  const [error, setError] = useState(false);

  const [isLoading, setIsLoading] = useState(false); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); 

  const username2 = 'melindo';
  const password2 = '$7h$62$7Kl3FpRtNSqJwM9xkGnEGuwcHXt2lBjz1.tTYnQvkBiN/ZDyDx6/K';
  const encodedCredentials51 = btoa(`${username2}:${password2}`);



  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || i18n.language // Get initial language
  );

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage); // Update i18n library
  }, [selectedLanguage]); // Update on language change

  const handleLanguageChange = (newLanguage) => {
    setSelectedLanguage(newLanguage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

      setError(false);

      setIsLoading(true);
      setIsButtonDisabled(true);
  
    const data = {
      "name" : name,
      "tel" : tel,
      "email" : email,
      "messagecontact" : message,
    };

    axios.post('https://api2.mexicolindoyqueridoproduce.com/emailcontact', data, {
      headers: {
        'Authorization': `Basic ${encodedCredentials51}`,
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
        console.log('Éxito:', response.data);
        Swal.fire({
          title: 'Success',
          text: 'The operation was successful.',
          icon: 'success',
          confirmButtonText: 'OK'
        })
    })
    .catch((error) => {
      console.error('Error:', error.message);
      Swal.fire({
        title: 'Error',
        text: error.message || 'There was an error processing the request.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    });
      setIsLoading(false);
      setIsButtonDisabled(false);

  } 


  return (
<div>

  <section className="page-section" id="services">
    <div className="container">
      <div className="text-center">
        <h2 className="section-heading text-uppercase">{t("header.company")}</h2>
        <h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
      </div>
      <div className="row text-center">
        <div className="col-md-4">
          <img className="mx-auto rounded-circle" style={{ width: '150px', height: '150px' }}  alt="mision" src="assets/img/mexicolindo.jpg"></img>
          <h4 className="my-3">{t("header.mision")}</h4>
          <p className="text-muted">{t("header.misiontext")}</p>
        </div>
        <div className="col-md-4">
        <img className="mx-auto rounded-circle" style={{ width: '150px', height: '150px' }}  alt="mision" src="assets/img/vision.jpg"></img>
          <h4 className="my-3">{t("header.vision")}</h4>
          <p className="text-muted">{t("header.visiontext")}</p>
        </div>
        <div className="col-md-4">
        <img className="mx-auto rounded-circle" style={{ width: '150px', height: '150px' }}  alt="mision" src="assets/img/certificaciones.jpg"></img>
          <h4 className="my-3">{t("header.certificaciones")}</h4>
          <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima maxime quam architecto quo inventore harum ex magni, dicta impedit.</p>
        </div>
      </div>
    </div>
  </section>
  {/* Portfolio Grid*/}
  <section className="page-section bg-light" id="portfolio">
    <div className="container">
      <div className="text-center">
        <h2 className="section-heading text-uppercase">{t("header.product")}</h2>
        <h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
      </div>
      <div className="row">
        <div className="col-lg-4 col-sm-6 mb-4">
          {/* Portfolio item 1*/}
          
          {/* Portfolio item 4*/}
          <div className="portfolio-item">
            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal4">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x" /></div>
              </div>
              <img className="img-fluid" src="assets/img/portfolio/4.jpg" alt="..." />
            </a>
            <div className="portfolio-caption">
              <div className="portfolio-caption-heading">{t("header.chiliycilantro")}</div>
              <div className="portfolio-caption-subheading text-muted">{t("header.product")}</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 mb-4 mb-sm-0">
          {/* Portfolio item 5*/}
          <div className="portfolio-item">
            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal5">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x" /></div>
              </div>
              <img className="img-fluid" src="assets/img/portfolio/5.jpg" alt="..." />
            </a>
            <div className="portfolio-caption">
              <div className="portfolio-caption-heading">{t("header.onionytomate")}</div>
              <div className="portfolio-caption-subheading text-muted">{t("header.product")}</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          {/* Portfolio item 6*/}
          <div className="portfolio-item">
            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal6">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x" /></div>
              </div>
              <img className="img-fluid" src="assets/img/portfolio/6.2.jpg" alt="..." />
            </a>
            <div className="portfolio-caption">
              <div className="portfolio-caption-heading">{t("header.avocadoytomate")}</div>
              <div className="portfolio-caption-subheading text-muted">{t("header.product")}</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 mb-4">
          {/* Portfolio item 1*/}
          <div className="portfolio-item">
            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal1">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x" /></div>
              </div>
              <img className="img-fluid" src="assets/img/portfolio/1.jpg" alt="..." />
            </a>
            <div className="portfolio-caption">
              <div className="portfolio-caption-heading">{t("header.carrotycactus")}</div>
              <div className="portfolio-caption-subheading text-muted">{t("header.product")}</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 mb-4">
          {/* Portfolio item 2*/}
          <div className="portfolio-item">
            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal2">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x" /></div>
              </div>
              <img className="img-fluid" src="assets/img/portfolio/2.jpg" alt="..." />
            </a>
            <div className="portfolio-caption">
              <div className="portfolio-caption-heading">{t("header.lettuce")}</div>
              <div className="portfolio-caption-subheading text-muted">{t("header.product")}</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 mb-4">
          {/* Portfolio item 3*/}
          <div className="portfolio-item">
            <a className="portfolio-link" data-bs-toggle="modal" href="#portfolioModal3">
              <div className="portfolio-hover">
                <div className="portfolio-hover-content"><i className="fas fa-plus fa-3x" /></div>
              </div>
              <img className="img-fluid" src="assets/img/portfolio/3.jpg" alt="..." />
            </a>
            <div className="portfolio-caption">
              <div className="portfolio-caption-heading">{t("header.lemonychili")}</div>
              <div className="portfolio-caption-subheading text-muted">{t("header.product")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* About*/}
  <section className="page-section" id="about">
    <div className="container">
      <div className="text-center ">
        <h1 className="custom-smaller">{t("header.stages")}</h1>
        <h3 className="section-subheading text-muted">{t("header.stagestext")}</h3>
      </div>
      <ul className="timeline">
        <li>
          <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/register.jpg" alt="..." /></div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <h4 className="subheading">{t("header.primary")}</h4>
            </div>
            <div className="timeline-body"><p className="text-muted">{t("header.primarytext")}</p></div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/about3.jpg" alt="..." /></div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <h4 className="subheading">{t("header.gathering")}</h4>
            </div>
            <div className="timeline-body"><p className="text-muted">{t("header.gatheringtext")}</p></div>
          </div>
        </li>
        <li>
          <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/about4.jpg" alt="..." /></div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <h4 className="subheading">{t("header.packaging")}</h4>
            </div>
            <div className="timeline-body"><p className="text-muted">{t("header.packagingtext")}</p></div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/about5.jpg" alt="..." /></div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <h4 className="subheading">{t("header.exportation")}</h4>
            </div>
            <div className="timeline-body"><p className="text-muted">{t("header.exportationtext")}</p></div>
          </div>
        </li>
        <li>
          <div className="timeline-image"><img className="rounded-circle img-fluid" src="assets/img/about/About1.jpg" alt="..." /></div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <h4 className="subheading">{t("header.commercialization")}</h4>
            </div>
            <div className="timeline-body"><p className="text-muted">{t("header.commercializationtext")}</p></div>
          </div>
        </li>
        
        <li className="timeline-inverted">
          <div className="timeline-image">
            <h4>
            {t("header.bepart")}
              <br />
              {t("header.ofour")}
              <br />
              {t("header.story")}
            </h4>
          </div>
        </li>
      </ul>
    </div>
  </section>
  {/* Team*/}
  <section className="page-section bg-light" id="team">
    <div className="container">
      <div className="text-center">
        <h2 className="section-heading text-uppercase">{t("header.amazingteam")}</h2>
        <h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="team-member">
            <img className="mx-auto rounded-circle" src="assets/img/team/polito.jpeg" alt="polito" />
            <h4>Hipolito Muñoz</h4>
            <p className="text-muted">Founder CEO</p>
          </div>
        </div>
        
        <div className="col-lg-6">
          <div className="team-member">
            <img className="mx-auto rounded-circle" src="assets/img/team/1.jpg" alt="..." />
            <h4>Alejandro Villalobos</h4>
            <p className="text-muted">Founder CEO</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mx-auto text-center"><p className="large text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut eaque, laboriosam veritatis, quos non quis ad perspiciatis, totam corporis ea, alias ut unde.</p></div>
      </div>
    </div>
  </section>
  <section className="page-section" id="contact">
    <div className="container">
      <div className="text-center">
        <h2 className="section-heading text-uppercase">{t("header.contactus")}</h2>
        <h3 className="section-subheading text-muted">Lorem ipsum dolor sit amet consectetur.</h3>
      </div>
      {/* * * * * * * * * * * * * * * **/}
      {/* * * SB Forms Contact Form * **/}
      {/* * * * * * * * * * * * * * * **/}
      {/* This form is pre-integrated with SB Forms.*/}
      {/* To make this form functional, sign up at*/}
      {/* https://startbootstrap.com/solution/contact-forms*/}
      {/* to get an API token!*/}
      <form id="contactForm" data-sb-form-api-token="API_TOKEN">
        <div className="row align-items-stretch mb-5">
          <div className="col-md-6">
            <div className="form-group">
              {/* Name input*/}
              <input className="form-control" id="name" type="text" placeholder="Name/Nombre*" data-sb-validations="required" autoComplete="name" 
              value={name} onChange={(e) => setName(e.target.value)}/>
              <div className="invalid-feedback" data-sb-feedback="name:required">A name is required.</div>
            </div>
            <div className="form-group">
              {/* Email address input*/}
              <input className="form-control" id="email" type="email" placeholder="Email*" data-sb-validations="required,email" autoComplete="email"
             value={email} onChange={(e) => setEmail(e.target.value)} />
              <div className="invalid-feedback" data-sb-feedback="email:required">An email is required.</div>
              <div className="invalid-feedback" data-sb-feedback="email:email">Email is not valid.</div>
            </div>
            <div className="form-group mb-md-0">
              {/* Phone number input*/}
              <input className="form-control" id="phone" type="tel" placeholder="Phone/Télefono*" data-sb-validations="required" autoComplete="phone"
              value={tel} onChange={(e) => setTel(e.target.value)}/>
              <div className="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>
            </div>
          </div>
          <div className="col-md-6">
            {/* <div className="form-group"> */}
            <div className="form-group form-group-textarea mb-md-0">
              {/* Message input*/}
              <textarea type= "text" className="form-control" id="message" placeholder="Message/Mensaje*" data-sb-validations="required"   style={{height: '245px' }}
              value={message} onChange={(e) => setMessage(e.target.value)} />
              <div className="invalid-feedback" data-sb-feedback="message:required">A message is required.</div>
            </div>
          </div>
        </div>
        {/* Submit success message*/}
        {/**/}
        {/* This is what your users will see when the form*/}
        {/* has successfully submitted*/}
        <div className="d-none" id="submitSuccessMessage">
          <div className="text-center text-white mb-3">
            <div className="fw-bolder">Form submission successful!</div>
            To activate this form, sign up at
            <br />
            <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
          </div>
        </div>
        {/* Submit error message*/}
        {/**/}
        {/* This is what your users will see when there is*/}
        {/* an error submitting the form*/}
        <div className="d-none" id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending message!</div></div>
        {/* Submit Button*/}
        <div className="text-center"><button className="btn btn-primary btn-xl text-uppercase" id="submitButton" type="submit"
        disabled={isButtonDisabled} onClick={handleSubmit} >
          {isLoading ? 'Loading...' : ""} {t("header.send")}</button></div>
      </form>
    </div>
  </section>
</div>

  )
}

export default Home;