import React, { useState, useEffect } from 'react';
// import {useLocalStorage} from 'react-use';
import IconoTexto from './IconoTexto';
import './styles.css';
import { useTranslation } from 'react-i18next';

function Header() {
    const [t, i18n] = useTranslation("global");
    const [selectedLanguage, setSelectedLanguage] = useState(
        localStorage.getItem('selectedLanguage') || i18n.language // Get initial language
      );
    
      useEffect(() => {
        localStorage.setItem('selectedLanguage', selectedLanguage);
        i18n.changeLanguage(selectedLanguage); // Update i18n library
      }, [selectedLanguage]); // Update on language change
    
      const handleLanguageChange = (newLanguage) => {
        setSelectedLanguage(newLanguage);
      };

  return (
    <div>
                {/* <!-- Navigation--> */}
                {/* <nav class="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav"></nav> */}
                
        <nav className="navbar navbar-expand-md navbar-dark fixed-top" id="mainNav">
            <div className="container">
                <a href="#page-top"><img src="assets/img/logo1.png" alt="..." className="imagen-logo"/></a>
                {/* <a class="navbar-brand" href="#page-top"><img src="assets/img/logo1.png" style={{ width: '80px', height: 'auto' }} alt="..." /></a> */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                {t("header.menu")}
                    <i className="fas fa-bars ms-1"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    {/* <ul class="navbar-nav text-uppercase ms-auto py-4 py-lg-0"> */}
                    <ul className="navbar-nav text-uppercase mx-auto py-4 py-lg-0 text-center">
                        <li className="nav-item"><a className="nav-link" href="#page-top"><IconoTexto icono="home"/>{t("header.home")}</a></li>
                        <li className="nav-item"><a className="nav-link" href="#services"><IconoTexto icono="work"/>{t("header.company")}</a></li>
                        <li className="nav-item"><a className="nav-link" href="#portfolio"><IconoTexto icono="compost"/>{t("header.product")}</a></li>
                        <li className="nav-item"><a className="nav-link" href="#team"><IconoTexto icono="groups"/>{t("header.team")}</a></li>
                        <li className="nav-item"><a className="nav-link" href="#contact"><IconoTexto icono="email"/>{t("header.contact")}</a></li>
                        <a   className="nav-item" href="https://dash.mexicolindoyqueridoproduce.com/#/login">
                        <button 
                        className="btn2 btn-primary btn-m text-uppercase" style={{height: '60px' }}>
                            <IconoTexto icono="login"/>
                            {t("header.login")}</button></a>
                        <a  className="nav-item" href="https://dash.mexicolindoyqueridoproduce.com/#/register"> 
                        <button 
                        className="btn2 btn-primary btn-m text-uppercase " style={{height: '60px' }}>
                            <IconoTexto icono="how_to_reg"/>
                            {t("header.signin")}</button></a>

                            <div className="dropdown">
                            <button className="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <IconoTexto icono="g_translate"/>
                            </button>
                            <ul className="dropdown-menu">
                            <div className="mr-1 d-sm-block">
                                            <button className="btn btn-sm" onClick={() => handleLanguageChange("es")}>Español</button>
                                        </div>
                                        <div className="mr-1 d-sm-block">
                                            <button className="btn btn-sm" onClick={() => handleLanguageChange("en")}>English</button>
                                        </div>
                            </ul>
                            </div>
       
                    </ul>
                </div>
            </div>
        </nav>
        {/* <!-- Masthead--> */}
        <header className="masthead">
            <div className="container">
                <div className="masthead-subheading"></div>
                <div className="masthead-heading text-uppercase"></div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <div className="elemento-a-wrapper">
                <a className="btn btn-primary btn-xl text-uppercase " href="#services">{t("header.tellme")}</a>
                </div>
            </div>

            
        </header>
        </div>
  )
}

export default Header